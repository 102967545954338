.contact-text{
    padding: 20px 0px;
    font-family: Poppins, sans-serif;
    font-size: 18px;
}

.contact-text h1{
    padding: 20px 0px;
}

.contact-text h3{
    padding: 20px 0px;
}

.contact-text span{
    padding: 20px 0px;
}

.contact-text a{
    color: black;
}

.contact-text a:hover{
    color: dodgerblue;
    transition: 0.23s;
}