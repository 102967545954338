.blog{
    padding: 40px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.blog-post{
    margin-top: 30px;
    margin-bottom: 30px;
}

.blog-title{
    color: dodgerblue;
    font-weight: bold;
}