.inm-text{
    padding: 20px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.inm-table{
    font-family: Poppins,sans-serif;
    font-size: 20px;
    margin-bottom: 20px;
}

.inm-table{
    border-collapse: collapse;
}

.inm-table th{
    color: dodgerblue;
}

.inm-table th,
.inm-table td{
    border: 1px solid #000;
    padding: 8px;
}

.inm-vids{
    padding: 20px 20px;
}

.inm-vids iframe{
    padding: 0px 10px;
    border: none;
}

.inm-slider{
    padding: 20px 0px;
    margin-bottom: 40px;
}

/* RESPONSIVE DESIGN */

@media (max-width: 650px) {
    .inm-vids iframe{
        width: 400px;
    }
}

/* RESPONSIVE DESIGN */