.prep-text{
    padding: 20px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.prep-paragraph{
    padding: 20px 0px;
    margin-bottom: 20px;
}

.prep-paragraph h3{
    color: dodgerblue;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 20px;
}

.prep-slider{
    margin-bottom: 40px;
}

.slider-img{
    max-height: 600px;
}