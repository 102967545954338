.ist{
    padding: 40px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.ist h3{
    margin-top: 40px;
    margin-bottom: 40px;
    color: dodgerblue;
    font-size: 40px;
    font-weight: bold;
}