.ilt-accordion{
    padding: 40px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.ilt-maintitle{
    padding: 20px 0px;
    font-family: Poppins,sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: dodgerblue;
}

.acc-btns{
    display: flex;
    flex-direction: column;
    width: 20%;
}

.acc-btn{
    padding: 10px 20px;
    color: white;
    background-color: dodgerblue;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.acc-btn:hover{
    color: white;
}

/* .acc-btn{
    display: inline-block;
    padding: 10px 20px;
    background-color: dodgerblue;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
} */