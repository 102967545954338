h2{
    font-family: Poppins,sans-serif;
}
.test-main{
    padding: 20px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.test-accordion{
    padding: 20px 0px;
    margin-bottom: auto;
    font-family: Poppins,sans-serif;
    font-size: 18px;
}

.accordion-header{
    font-size: 30px;
    font-weight: 600;
}

.accordion-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.accordion-img{
    max-height: 250px;
    max-width: 250px;
}

.test-testimonies{
    display: flex;
    align-items: center;
    margin-bottom: 40px
}

.ceo-text{
    padding-left: 15px;
    font-family: Poppins,sans-serif;
    font-size: 22px;
}

.ceo-text-p{
    padding: 10px 0px;
}

.ceo-text-ul a{
    color: dodgerblue;
}


/* RESPONSIVE DESIGN */

@media (max-width: 942px) {
    .test-testimonies{
        display: flex;
        flex-direction: column;
    }
    .ceo-text-p{
        margin-top: 20px;
    }
    .ceo-text-ul{
        margin-left: -30px;
    }
}

/* RESPONSIVE DESIGN */