.staff-slider{
    padding: 20px 0px;
}

.staff-text p{
    padding: 20px 0px;
    font-family: Poppins, sans-serif;
    font-size: 20px;
}

.staff-title{
    color: dodgerblue;
    font-family: Poppins, sans-serif;
    font-size: 40px;
    font-weight: bold;
    margin-top: 30px;
}