.eday-text{
    padding: 20px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.eday-text h3{
    color: dodgerblue;
    font-family: Poppins,sans-serif;
    font-size: 40px;
    padding: 20px 0px;
    font-weight: bold;
}

.eday-text p{
    padding: 20px 0px;
}

.eday-slider{
    margin-bottom: 40px;
}