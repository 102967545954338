* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

nav{
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px rgba(0,0,0,.09);
    font-size: 1.7rem;
    height: auto;
    left: 0;
    padding: 25px 40px 25px 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
    font-family: Poppins,sans-serif;
}

nav h3{
  color: black;
  font-size: 30px;
  font-weight: bold;
}

.logo{
    color: #2d2e32;
    cursor: pointer;
    font-weight: bold;
}

nav ul{
    display: flex;
    gap: 2rem;
    list-style: none;
}

.nav-links{
    color: #2d2e32;
    font-weight: 700;
    text-decoration: none;
    transition: all .23s;
}

.nav-links:hover{
    color: dodgerblue;
}

nav h3:hover{
  color: dodgerblue;
}


/* LMAO */

.nav-item {
    position: relative;
  }
  
  .nav-link {
    color: #2d2e32;
    font-weight: 600;
    text-decoration: none;
    transition: all .23s;
    position: relative;
  }
  
  .nav-link:hover {
    color: dodgerblue;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
  }
  
  .dropdown-link {
    display: block;
    padding: 10px;
    color: #2d2e32;
    text-decoration: none;
    transition: all .23s;
  }
  
  .dropdown-link:hover {
    background-color: #f2f2f2;
  }

/* LMAO */


/* Mobile Menu Styles */

.mobile-menu-icon{
    display: none;
}

/* Mobile Menu Styles */

/* Responsive Design */

@media (max-width: 900px){
    .nav-links{
        display: none;
    }

    .mobile-menu-icon{
        display: flex;
    }
}

/* Responsive Design */