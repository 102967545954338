* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body{
  font-family: 'Blinker', sans-serif;
  position: relative;
  color: black;
}

html{
  font-size: 80%;
}

/* Global */
.container{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 19px;
  color: black;
}