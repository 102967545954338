.courses-slider{
    padding: 20px 0px;
}

.courses-text{
    padding: 20px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.courses-text span{
    font-weight: bold;
}

.text-paragraph{
    padding: 20px 0px;
}

.text-paragraph h3{
    color: dodgerblue;
    font-size: 30px;
    font-weight: bold;
}

.text-paragraph li{
    margin-left: 30px;
    list-style: circle;
}

.text-paragraph a{
    color: dodgerblue;
    font-weight: bold;
}

/* RESPONSIVE DESIGN */

@media (max-width: 942px) {
    .text-paragraph ul{
        margin-left: -30px;
    }
}

/* RESPONSIVE DESIGN */