.choco-text{
    padding: 20px 0px;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

.choco-text h3{
    font-size: 40px;
    color: dodgerblue;
    font-weight: bold;
}

.choco-vids{
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}

.choco-vids iframe{
    border: none;
}

.bassy{
    margin-bottom: 40px;
}