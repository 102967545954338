.toeic{
    display: flex;
    padding: 20px 0px;
}

.toeic-img img{
    max-width: 300px;
    max-height: 300px;
}

.toeic-text{
    font-family: Poppins,sans-serif;
    font-size: 20px;
    margin-left: 20px;
}

.toe-par h3{
    color: dodgerblue;
    padding: 20px 0px;
}

.toeic-text{
    margin-bottom: 20px;
}

/* RESPONSIVE DESIGN */

@media (max-width: 790px) {
    .toeic{
        display: flex;
        flex-direction: column;
    }
    .toeic-text{
        margin-bottom: -20px;
    }
    .toeic-img{
        margin-bottom: 20px;
        align-self: center;
    }
    .toe-par-last{
        margin-bottom: 60px;
    }
}

/* RESPONSIVE DESIGN */