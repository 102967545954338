.tutor{
    display: flex;
    padding: 120px 0px;
}

.tutor-img img{
    max-width: 300px;
    max-height: 300px;
}

.tutor-text{
    font-family: Poppins,sans-serif;
    font-size: 20px;
    margin-left: 20px;
}

.tutor-text span{
    font-weight: bold;
}

/* RESPONSIVE DESIGN */

@media (max-width: 995px) {
    .tutor{
        display: flex;
        flex-direction: column;
    }
    .tutor-text{
        margin-bottom: -60px;
    }
    .tutor-img{
        margin-top: -60px;
        margin-bottom: 20px;
        align-self: center;
    }
}

/* RESPONSIVE DESIGN */