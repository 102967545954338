.dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    z-index: 1;
}

.dropdown ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown li {
    padding: 5px;
}

.nav-links + .dropdown {
    display: none;
}

.nav-links:focus + .dropdown {
    display: block;
}

/* .dropdown-link{
    color: red;
} */

.dropList a{
    color: black;
}

.dropList a:hover{
    color: dodgerblue;
    transition: 0.23s;
}
