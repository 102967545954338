.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }
  
  .mobile-menu.open {
    transform: translateX(0);
  }

  
  .mobile-menu-content {
    background-color: #fff;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-link {
    display: block;
    padding: 10px 0;
    color: #333;
    text-decoration: none;
  }
  
  .nav-link:hover {
    color: dodgerblue;
  }

.left-link-title{
    color: dodgerblue;
}

.last-left-link-title{
    color: dodgerblue;
    text-decoration: underline
}

.last-left-link-title:hover{
    cursor: pointer;
}


/* Mobile Menu Structure */

.menu-structure{
    display: flex;
    flex-direction: column;
}

.ms-link{
    display: flex;
}

.ms-left{
    display: flex;
    align-items: center;
}

.ms-right{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.right-links{
    display: flex;
    flex-direction: column;
}

.mobile-menu-hr {
    margin: 0.5rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: .25;
}

/* Mobile Menu Structure */

/* Responsive Design */
/* Closes the Mobile Menu when the screen is Bigger than 900px.*/

@media (max-width: 770px){
    .mobile-menu-hr{
        display: none;
    }
}

@media (min-width: 900px) {
    .mobile-menu.open {
      display: none;
    }
  }

/* Responsive Design */