.desc{
    display: flex;
    padding: 100px 0px;
}

.desc-img img{
    max-width: 300px;
    max-height: 300px;
    margin-top: 50px;
}

.desc-text{
    font-family: Poppins,sans-serif;
    font-size: 20px;
    margin-left: 20px;
}

.desc-text h3{
    color: dodgerblue;
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: bold;
}

.desc-text span{
    font-weight: bold;
}

.text-par{
    padding: 10px 0px;
}

/* RESPONSIVE DESIGN */

@media (max-width: 775px) {
    .desc{
        display: flex;
        flex-direction: column;
    }
}

/* RESPONSIVE DESIGN */